
@import "./node_modules/bootstrap/scss/bootstrap";
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

label{
  font-weight:bold
}

.header{
width: 100%;
height: 3em;
background-color:#c7444a;
position:fixed;
z-index:50;
text-align:center;
color:white;
font-size: 1.2em;
font-weight: bold;
}

.corpo{
padding-top: 4em;
padding-left: 1em;
padding-right: 1em;
}


.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: white;
  font-weight: bold;
  background-color: #c7444a;
}

.nav-tabs .nav-link, .nav-tabs .nav-item.show .nav-link {
  color: black;
  font-weight: bold;
  background-color: #fcfcfc;
  margin:1px
}

.panel .customClass{
  background-color: #c7444a;
  font-size:small;
} 

.panel .customClass .card-header .btn{
  padding:2px;
  color: #fff;
  font-size: 0.85em;
  font-weight: bold;
  text-transform: uppercase;
} 

.panel .customClass .card-body{
  background-color: #fff;
  color: #000;
} 

.panel .customClass .card-body .btn{
  color: #c7444a;
  font-size: 0.85em;
} 

.errorMsg{
  color:#c7444a;
  font-size:12px;
}

.modal{
  padding:20px;
  position: fixed;
  overflow: auto;
  background-color: white;
  display: none;
  z-index:999;
}

.botoesBox{
  display: flex;  
  justify-content: space-around;
}